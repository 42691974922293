<!-------------------------------------------TITLE--------------------------------------------------->
<div class="bg-light">
    <div class="text-center">
        <div class="contact-detail">
            <img src="./../../../assets/img/Maintaince.jpeg" alt="Notebook" style="width:100%; height: 300px;">
            <div class="content p-4">
                <br /><br /><br />
                <h1 class="font-weight-bolder align-middle text-uppercase">MAINTENANCE</h1>
            </div>
        </div>
    </div>
<!------------------------------------------TITLE END-------------------------------------------------->
<!-------------------------------------------CARD IMAGE---------------------------------------------------->
<div class="container">
    <div class="row">
        <div  class="col-md-4 col-sm-12 col-xs-12">
            <div class="card 1">
                <div class="card_image"> <img src="./../../../assets/img/home/maintain/main.jpg" /> </div>
                <div class="card_title title-white">
                </div>
              </div>
        </div>
<!------------------------------------------CARD IMAGE END--------------------------------------------------->

<!----------------------------------------MAINTENANCE------------------------------------------------------->
        <div  class="col-md-6 col-sm-12 col-xs-12"  ><br><br><br>
            <h5><u><b>MAINTENANCE</b></u></h5>
            <p class="justify-content-center" style="text-indent: 10%;">
               <b><b>A</b></b>nnaa Silicon maintenance and support teams are competent
                and have qualified engineers concentrating solely on maintenance and support activities.
                 Whether it is a software installation, daily service or product support or system
                  upgrades and improvements, our team will be an efficient
                resource to help you maximize return on investment and improve your business.</p>
        </div>
    </div>
</div>
<!-----------------------------------------MAINTENANCE END-------------------------------------------->
<div class="pb-5"></div>
