import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss']
})
export class UploadCvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
