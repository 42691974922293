<!---------------------------------------------------- HEADER IMAGE ----------------------------------------------------->
<div class="text-center">
	<div class="contact-detail">
		<img src="./../../assets/img/Vertical.gif" alt="Notebook" style="width:100%; height: 300px;">
		<div class="content p-4">
			<br /><br /><br />
			<h1 class="font-weight-bolder align-middle text-uppercase">Verticals</h1>
		</div>
	</div>
</div>
<!----------------------------------------------------------------END ----------------------------------------------------->

<!------------------------------------------------------------CAROSUEL----------------------------------------------------->
<div class="container-fluid">
	<div id="demo" class="carousel slide" data-ride="carousel">

		<!-- Indicators -->
		<ul class="carousel-indicators" style="display: none;">
		  <li data-target="#demo" data-slide-to="0" class="active"></li>
		  <li data-target="#demo" data-slide-to="1"></li>
		  <li data-target="#demo" data-slide-to="2"></li>
		</ul>

		<!-- The slideshow -->
		<div class="carousel-inner">
		  <div class="carousel-item active">
			<div class="row">
				<div class="col-md-4 col-sm-12 col-xs-12">

						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-hospital fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">Hospital Management System</dt><br>
										<dd>- Health Care</dd>
									  </dl>
								</div>
							</div>
						</div>
					</div>

				<div class="col-md-4 col-sm-12 col-xs-12">
					<div class="card p-5"  style= "margin-top: 10px;">
						<div class=" text-center justify-content-center align-items-center">
							<i class="fas fa-gopuram fa-3x" aria-hidden="true"></i><br><br>
							<div class="card_title">
								<dl>
									<dt class="font-weight-bold">Temple Management System</dt><br>
									<dd>- Religious</dd>
								  </dl>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-4 col-sm-12 col-xs-12">
					<div class="card p-5"  style= "margin-top: 10px;">
						<div class=" text-center justify-content-center align-items-center">
							<i class="fa fa-hotel fa-3x" aria-hidden="true"></i><br><br>
							<div class="card_title">
								<dl>
									<dt class="font-weight-bold">Hotel Management System</dt><br>
									<dd>- Restaurants</dd>
								  </dl>
							</div>
						</div>
					</div>
				</div>
			</div>
		  </div>
			<div class="carousel-item">
				<div class="row">
					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fas fa-school fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">School Management System</dt><br>
										<dd>- Education</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>


					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-university fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">College Management System</dt><br>
										<dd>- Education</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>



					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-users fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">Value Added Services</dt><br>
										<dd>- BULK SMS</dd>
										<dd>- Bio metric Solutions</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
			</div>
		</div>

			<div class="carousel-item">
				<div class="row">
					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-shopping-cart fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">Retail</dt><br>
										<dd>- Chits Management</dd>
										<dd>- Jewellery Management</dd>
										<dd>- Bakery Management</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>



					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-shopping-cart fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">Retail</dt><br>
										<dd>- Pawn Broking</dd>
										<dd>- Whole Sale Management</dd>
										<dd>- Textile Management</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>



					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="card p-5"  style= "margin-top: 10px;">
							<div class=" text-center justify-content-center align-items-center">
								<i class="fa fa-shopping-cart fa-3x" aria-hidden="true"></i><br><br>
								<div class="card_title">
									<dl>
										<dt class="font-weight-bold">Retail</dt><br>
										<dd>- Furniture Management</dd>
										<dd>- Super market</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Left and right controls -->
			<a class="text-dark carousel-control-prev" href="#demo" data-slide="prev">
				<span class="carousel-control-prev-icon"></span>
			  </a>
			  <a class="text-dark carousel-control-next" href="#demo" data-slide="next">
				<span class="carousel-control-next-icon"></span>
			  </a>
			</div>
		</div>
</div>
