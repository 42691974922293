<!------------------------------------------ HEADER IMAGE --------------------------------------->
<div class="text-center">
  <div class="contact-detail">
      <img src="./../../assets/img/Clinent.gif" alt="Notebook" style="width:100%; height: 300px;">
      <div class="content p-4">
          <br /><br /><br />
          <h1 class="font-weight-bolder align-middle text-uppercase">Clients</h1>
      </div>
  </div>
</div>
<!------------------------------------------ END ------------------------------------------------->

<!------------------------------------------ WEBSITES---------------------------------------------->
<div class="container" style="font-size: 40px; font-family: 'Arvo', 'serif', Times, serif;">
  <div class="button-default button-slanted">
    <span class="button-slanted-content">Websites</span>
 </div>
</div>
<div class="container">
<div class="row p-4">
<!---------------------------------------- Manimadam ThiruKovil----------------------------------->
    <div class="col-md-3">
      <div class="box12">
        <img src="./../../assets/img/clients/img-01.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Manimadam ThiruKovil</h3>
            <ul class="icon">
                <li><a href="http://manimadam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
         </div>
      </div>
    </div>

<!------------------------------------------Gurukrupa Consultancy------------------------------------>
    <div class="col-md-3">
      <div class="box12">
        <img src="./../../assets/img/clients/img-02.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Gurukrupa Consultancy</h3>
            <ul class="icon">
                <li><a href="http://gurukrupaconsultancy.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

<!-----------------------------------------Gowmariyamman Temple---------------------------------------->
    <div class="col-md-3 image-grid">
      <div class="box12">
        <img src="./../../assets/img/clients/img-03.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title">Gowmariyamman Temple</h3>
            <ul class="icon">
                <li><a href="http://gowmariyamman.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
    </div>

<!------------------------------------------Sri Vanchinathar Temple --------------------------------->
    <div class="col-md-3">
      <div class="box12">
        <img src="./../../assets/img/clients/img-04.jpg"  class="image" style="width:100%; height:100%;">
          <div class="box-content">
            <h3 class="title"> Sri Vanchinathar Temple</h3>
            <ul class="icon">
                <li><a href="http://srivaanjiyam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
            </ul>
          </div>
      </div>
     </div><br>
    </div>

  <div class="row p-4">

<!--------------------------------------- Sarangapani Swamy Temple-------------------------------->
      <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/clients/img-05.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
              <h3 class="title"> Sarangapani Swamy Temple</h3>
              <ul class="icon">
                  <li><a href="http://sarangapanitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
              </ul>
            </div>
        </div>
      </div>

<!---------------------------------------Villagers Construction------------------------------------>
      <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/clients/img-06.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
              <h3 class="title"> Villagers Construction</h3>
              <ul class="icon">
                  <li><a href="http://villagersconstruction.com/" target="_blank"><i class="fa fa-link"></i></a></li>
              </ul>
            </div>
        </div>
      </div>

<!-------------------------------------Samayapuram Maariamman------------------------------------->
      <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/clients/img-33.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
              <h3 class="title">Samayapuram Maariamman</h3>
              <ul class="icon">
                  <li><a href="http://samayapurammariammantemple.in/" target="_blank"><i class="fa fa-link"></i></a></li>
              </ul>
            </div>
        </div>
      </div>

<!------------------------------------Seshapureeswarar Temple--------------------------------------->
      <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/clients/img-08.jpg"  class="image" style="width:100%; height:100%;">
           <div class="box-content">
              <h3 class="title"> Seshapureeswarar Temple</h3>
                <ul class="icon">
                  <li><a href="http://raghukethutemplethiruppampuram.org/" target="_blank"><i class="fa fa-link"></i></a></li>
                </ul>
           </div>
        </div>
      </div>

  </div>

  <div class="row p-4">

<!------------------------------------Vallalar Temple---------------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-34.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Vallalar Temple</h3>
          <ul class="icon">
              <li><a href="http://vallalardeivanilayam.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!------------------------------------ Kumbakonam Home Stay---------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-11.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Kumbakonam Home Stay</h3>
          <ul class="icon">
              <li><a href="http://kumbakonamhomestay.com/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
      </div>
    </div>
  </div>
<!----------------------------------Anjaneyar Swamy Temple-------------0---------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-12.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Anjaneyar Swamy Temple</h3>
          <ul class="icon">
              <li><a href="http://namakkalnarasimhaswamianjaneyartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!--------------------------------------Subramaniya Swamy-Ettukudi------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-27.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Subramaniya Swamy-Ettukudi</h3>
          <ul class="icon">
              <li><a href="http://ettukudimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

</div>

<div class="row p-4">

<!---------------------------------Swetharanyeswarar Temple--------------------------------------->

  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-13.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Swetharanyeswarar Temple</h3>
          <ul class="icon">
              <li><a href="http://thiruvengadubudhan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!----------------------------------Amirtha Kadeswarar Temple-------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-16.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Amirtha Kadeswarar Temple</h3>
          <ul class="icon">
              <li><a href="http://thirukkadaiyurabiramitemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!-------------------------------------Thillai Kali Amman Temple-------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-14.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Thillai Kali Amman Temple</h3>
          <ul class="icon">
              <li><a href="http://thillaikaliammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!------------------------------------Aadhi Kumbeswarar Temple----------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-15.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Aadhi Kumbeswarar Temple</h3>
          <ul class="icon">
              <li><a href="http://adhikumbeswarar.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

</div>

<div class="row p-4">

<!-------------------------------------Naganatha Swamy Temple--------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-17.jpg"  class="image" style="width:100%; height:100%;">
       <div class="box-content">
          <h3 class="title"> Naganatha Swamy Temple</h3>
          <ul class="icon">
              <li><a href="http://kethutemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!------------------------------------Subramanya Swami-Thiruttani----------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-18.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Subramanya Swami-Thiruttani</h3>
          <ul class="icon">
              <li><a href="http://tiruttanigaimurugan.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!-------------------------------------Dhandayuthapani Temple-------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-19.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Dhandayuthapani Temple</h3>
          <ul class="icon">
              <li><a href="http://palanimurugantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
       </div>
    </div>
  </div>

<!--------------------------------Virudhagireeshwarar Temple----------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-20.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Virudhagireeshwarar Temple</h3>
          <ul class="icon">
              <li><a href="http://virudhagireeswartemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

</div>

<div class="row p-4">

<!-------------------------------------Idhaya College--------------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-32.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Idhaya College</h3>
          <ul class="icon">
              <li><a href="http://idhayacollegekum.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!---------------------------------- Theenupureeswarar Temple---------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-31.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Theenupureeswarar Temple</h3>
          <ul class="icon">
              <li><a href="http://patteeswaramdurga.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!---------------------------------------- Neelayathatchiamman Temple---------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-24.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title"> Neelayathatchiamman Temple</h3>
          <ul class="icon">
              <li><a href="http://neelayathatchiammantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!------------------------------------------Kumaran Temple---------------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/clients/img-25.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Kumaran Temple</h3>
          <ul class="icon">
              <li><a href="http://kumarantemple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>
  <div class="row p-4">

    <!--------------------------------------------Vallalar Temple------------------------------------------->
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/IMG_20230502_114609.jpg"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Vallalar Temple</h3>
          <ul class="icon">
              <li><a href="http://vallalar Temple.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!-------------------------------------------Carmel School------------------------------------------------>
  <div class="col-md-3">
    <div class="box12">
      <img src="./../../assets/img/Screenshot (62).png"  class="image" style="width:100%; height:100%;">
        <div class="box-content">
          <h3 class="title">Carmel School</h3>
          <ul class="icon">
              <li><a href="http://Carmel School.org/" target="_blank"><i class="fa fa-link"></i></a></li>
          </ul>
        </div>
    </div>
  </div>

<!-------------------------------------------- SOFTWARES -------------------------------------------->
<div class="container" style="font-size: 40px; font-family: 'Arvo', 'serif', Times, serif;">
  <div class="button-default button-slanted">
    <span class="button-slanted-content">SOFTWARES</span>
  </div>
 </div>
 <!-----------------------------------------=--------END-------------------------------------------->
 <div class="container">

  <div class="row p-4">

<!----------------------------------------------------  0ELLORA PHARMACY ------------------------------>
    <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/softwares/img-08.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
              <h3 class="title"> Ellora Pharmacy</h3>
              <ul class="icon">
                  <li><a  (click)="moveEllora()"><i class="fa fa-link"></i></a></li>
              </ul>
            </div>
        </div>
    </div>

<!---------------------------------------------- SCHOOL MANAGEMENT SYSTEM ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/softwares/img-09.jpg"  class="image" style="width:100%; height:100%;">
               <div class="box-content">
                  <h3 class="title">School Management System</h3>
                  <ul class="icon">
                      <li><a  (click)="moveSchool()"><i class="fa fa-link"></i></a></li>
                  </ul>
                </div>
          </div>
        </div>
<!---------------------------------------------- LODGE MANAGEMENT SYSTEM ------------------------------------------->
          <div class="col-md-3">
            <div class="box12">
              <img src="./../../assets/img/softwares/img-02.jpg"  class="image" style="width:100%; height:100%;">
                <div class="box-content">
                  <h3 class="title"> Lodge Management System</h3>
                    <ul class="icon">
                      <li><a  (click)="moveLodge()"><i class="fa fa-link"></i></a></li>
                     </ul>
                </div>
            </div>
          </div>
<!------------------------------------------------------- END ----------------------------------------------------->

<!----------------------------------------------INVENTORY SOFTWARE -------------------------------------------->
      <div class="col-md-3">
        <div class="box12">
          <img src="./../../assets/img/softwares/img-07.jpg"  class="image" style="width:100%; height:100%;">
            <div class="box-content">
              <h3 class="title">Inventory Software</h3>
                <ul class="icon">
                  <li><a  (click)="moveInventory()"><i class="fa fa-link"></i></a></li>
                </ul>
            </div>
        </div>
      </div>
<!---------------------------------------------END-------------------------------------------------------------->
  </div>

  <div class="row p-4">
<!---------------------------------------------- ANNAA ANTIBIOTIC ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/softwares/img-03.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Annaa Antibiotic</h3>
                  <ul class="icon">
                    <li><a  (click)="moveAntibiotic()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!------------------------------------------------------ END ------------------------------------------------------>

<!---------------------------------------------- TICKET BOOKING SOFTWARE ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/softwares/img-04.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Ticket Booking Software</h3>
                  <ul class="icon">
                    <li><a  (click)="moveTicket()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!--------------------------------------------------------- END -------------------------------------------------------->

<!---------------------------------------------- ENGINEERING SOFTWARE SYSTEM ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/softwares/img-05.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title"> Engineering Software System</h3>
                  <ul class="icon">
                    <li><a  (click)="moveEngineering()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END--------------------------------------------------------------->

<!---------------------------------------------STUDENT STIPEND SOFTWARE ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
           <img src="./../../assets/img/softwares/img-06.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Student Stipend Software</h3>
                  <ul class="icon">
                    <li><a  (click)="moveStipend()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------------------------------------------>

</div>

<div class="row p-4">

<!--------------------------------------- Inventory Management System-------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/IMG-20230502-WA0018.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Intentory Management System</h3>
                  <ul class="icon">
                    <li><a  (click)="moveStipend()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------------------------------------------>

<!-----------------------------------Establishment Management Ssytem------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/IMG-20230502-WA0017.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Establishment Management System</h3>
                  <ul class="icon">
                    <li><a  (click)="moveStipend()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------------------------------------------>

<!------------------------------------------ Parking Management Ssytem ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/IMG-20230502-WA0019.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Parking Management System</h3>
                  <ul class="icon">
                    <li><a  (click)="moveStipend()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------------------------------------------>

<!--------------------------------------Student Management Ssytem ------------------------------------------->
        <div class="col-md-3">
          <div class="box12">
            <img src="./../../assets/img/IMG-20230502-WA0024.jpg"  class="image" style="width:100%; height:100%;">
              <div class="box-content">
                <h3 class="title">Student Management System</h3>
                  <ul class="icon">
                    <li><a  (click)="moveStipend()"><i class="fa fa-link"></i></a></li>
                  </ul>
              </div>
          </div>
        </div>
<!---------------------------------------------END------------------------------------------------------------>

  </div>

  </div>
