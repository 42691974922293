<div class="bg-light">
    <div class="text-center">
        <div class="contact-detail">
            <img src="../../assets/img/Contact-us-Animated.webp" alt="Notebook" style="width:100%; height: 300px;">
            <div class="content p-4">
                <br /><br /><br />
                <h1 class="font-weight-bolder align-middle text-uppercase">Contact Us</h1>
            </div>
        </div>
    </div>
    <div class="container p-4">
        <!-- <div class="card"> -->
            <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12" style="padding-left: 0px;">
                        <div class="contact-detail">
                            <img src="../../assets/img/Buliding.jpg" alt="Notebook" style="width:100%; height: 550px;">
                            <div class="content p-4">
                                <br /><br /><br />
                                <h1 class="align-middle">Location</h1>
                                <h4 class="align-middlefont-weight-bold">Annaa Silicon Technology,</h4>
                                <p class="align-middle">
                                    No:44 A/47 Kumaragam, 2nd Floor, Abimukeswarar East Street,
                                    <br />
                                    Kumbakonam - 612 001. Thanjavur (Dt)
                                    <br />
                                    Tamilnadu, India.
                                </p>
                                <br />
                                <h1 class="">Phone | E-mail | Website</h1>
                                <p>
                                    +91 435 2427274
                                    <br />
                                    For Support : annaasilicontechnology@gmail.com, support@annaasilicontechnology.com
                                    <br />
                                    For Sales Enquiries : sales@annaasilicontechnology.com
                                </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12" style="background: #ffffff;">
                    <h1 class="text-center">Send Us A Message</h1>
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Name *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-user icon" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <input type="Name" class="form-control" id="inputEmail4" placeholder="Full Name">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Mobile Number *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-mobile icon" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <input type="number" class="form-control" id="inputEmail4" placeholder="mobile number">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">E-mail ID *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-envelope  icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="Email">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Enquiry Type  *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-user icon" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <select id="inputState" class="form-control">
                                    <option selected>Choose...</option>
                                    <option>...</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Write a Message *</label>
                                <textarea class="form-control" >

                            </textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        <!-- </div> -->
    </div>
    <div class="container-fluid">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1593.8738818056634!2d79.383564!3d10.955482!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x862f245681005e4a!2sAnna%20Silicon%20Technology!5e1!3m2!1sen!2sin!4v1583824512009!5m2!1sen!2sin"
        width="100%" height="300" frameborder="0" allowfullscreen="">
        </iframe>
    </div>
</div>
