<!-------------------------------------TOP TITLE---------------------------------------------------------->
<div class="bg-light">
  <div class="text-center">
      <div class="contact-detail">
          <img src="../../../assets/img/Gif 6.gif" alt="Notebook" style="width:100%; height: 300px;">
          <div class="content p-4">
              <br /><br /><br />
              <h1 class="font-weight-bolder align-middle text-uppercase">WEB HOSTING</h1>
          </div>
      </div>
  </div>
<!--------------------------------------------------END------------------------------------------------------>


<!---------------------------------WEB-HOSTING---------------------------------------------------------------->
<div class="container">
    <div class="row" >
    <div class="col-md-5 col-sm-12 col-xs-12" style="margin-top: 80px;">
                <div class="card c">
                  <div class="top-sec">
                    <img src="./../../../assets/img/Web hosting.jpg" style="width:100%;">
                  </div>
                  <div class="bottom-sec">

                  </div>
                </div>
    </div>
    <div class="col-md-7 col-sm-12 col-xs-12" style="margin-top: 80px;">
        <h5 >Web Hosting</h5>
        <p class="justify-content-center" style="text-indent: 5%;">
            Web Space is a small portion of a web server owned or rented by a web hosting company.
            This process of transferring a website into server web space is Web Hosting.
            Web hosting is basically a web space service provider. Factors to be considered while buying web
            space are Server Softwares, Space and Capacity.
            The common server types are Windows 2000, Unix and Linux. Disk space or Web Space is the size of
            space allotted on a particular server for uploading the web pages and Band Width is an amount of transfer
            from the web server thro internet. For a basic website, there is no need to bother about server type, disk
            space and band width, but if a website has data base then server type is very important as database suppor
            depends on its type. For example, a website with MS SQL database
            cannot be store in Linux Server but only in the Windows server. Annaa Silicon is currently developing mobile apps and mobile software components on
            iOS (iPhone and iPad), Android, Blackberry and Windows Phone 7.
        </p>
    </div>
  </div>
</div>

<button onclick="topFunction()" id="myBtn" title="Go to top"></button>
<!----------------------------------------------WEB-HOSTING END----------------------------------------------->

<!------------------------------------------TYPES OF WEB HOSTING---------------------------------------------->


<div class="container" >
    <div class="row">
      <div class="col-md-7 col-sm-12 col-xs-12" >
        <h4 style="padding-left: 20px;">Types of web hosting packages</h4>
          <p class="justify-content-center" style="text-indent: 5%;">
              Dedicated Hosting is expensive however very secure and highly recommended for websites
              with huge traffic /having e-Commerce Shopping</p>
          <p class=" justify-content-center" style="text-indent: 5%;">
              Shared Hosting is competitively priced where thousands of websites will be hosted and
              they share the same server and its features and works great for websites without any commerce
              / performance need.</p>
          <p class="justify-content-center" style="text-indent: 5%;">
              Co-Located web hosting is similar to dedicated hosting. Instead of having a own server
              can be rented out and to take responsibility of maintaining the server completely like
              except electricity, storage and internet facility.</p>
          <p class=" justify-content-center" style="text-indent: 5%;">
              Free Hosting is available based on promotions and often recommended for temporary sites/news casts.</p>
          <p class=" justify-content-center" style="text-indent: 5%;">
              Reseller Hosting which is similar to shared hosting with additional features of extra tools
              in order to Re-Sell the serer or web space into many units to the end users under their own brand name.</p>
           <p class=" justify-content-center" style="text-indent: 5%;"> Annaa Silicon is currently developing mobile apps and mobile software components on
              iOS (iPhone and iPad), Android, Blackberry and Windows Phone 7.</p>
        </div>
        <div class="col-md-5 col-sm-12 col-xs-12"  >
          <div class="card c">
              <div class="top-sec">
                  <img src="./../../../assets/img/home/serv-wh/Web-Hosting2.jpg"  style="width:100%;">
              </div>
              <div class="bottom-sec">
              </div>
          </div>
        </div>
    </div>
</div>

<!----------------------------------------------TYPES OF WEB HOSTING END--------------------------------------->


<!---------------------------------------------SEO SERVICES--------------------------------------------------->
<div class="container" >
    <div class="row">
      <div class="col-md-5 col-sm-12 col-xs-12" >
        <div class="card c">
            <div class="top-sec">
              <img src="./../../../assets/img/seo.jpg"  style="width:100%;">
            </div>
            <div class="bottom-sec">

            </div>
        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-xs-12" >
        <h5>SEO Services</h5>
        <p class="justify-content-center" style="text-indent: 5%;">
              Search Engine Optimization ( SEO ) is a process of making a website or a portal to be
              Search Engine Friendly, to bring that particular website or a portal with the Top Ranking on a
              Search Engine Ranking Page ( SERP ) as top as possible for a query raised while surfing.
              This drives more traffic to the website and in turn increase customers to the portal.
              This is very key for driving growth to a business.</p>
      </div>
    </div>
</div>

<!------------------------------------------SEO SERVICES--------------------------------------------------->
<div class="pb-5"></div>
