<!-----------------------------------------BACKGROUND TITLE--------------------------------------->
<div class="bg-light">
    <div class="text-center">
        <div class="contact-detail">

            <img src="./../../assets/img/Test 1.gif" alt="Notebook" style="width:100%; height: 400px;">
            <div class="content p-4">
                <br /><br /><br />
                <h1 class="font-weight-bolder align-middle text-uppercase">Testimonials</h1>
            </div>
        </div>
    </div>
<!---------------------------------------------BG TITLE END-------------------------------------------->
<!----------------------------------------------TESTIMONIAL FIGURE--------------------------------------->
<div style="width: 100%; margin: 0 auto; padding-top: 80px; padding-bottom: 80px;">

  <h1></h1>
  <div class="testimonial-quote group">
      <img src="./../../assets/img/home/test/test1.jpg">
      <div class="quote-container">
          <blockquote>
              <p>Annaa Silicon Technology always under commit and over deliver either be software, web products.”</p>
          </blockquote>
          <cite><span>Mr. S. Chandra Sekar,</span><br>
            Shri Chamundi Baking Equipments.
          </cite>
      </div>
  </div>

  <hr style="margin: 60px auto; opacity: .5;">

  <h1></h1>
  <div class="testimonial-quote group right">
      <img src="./../../assets/img/home/test/test2.jpg">
      <div class="quote-container">
          <div>
              <blockquote>
                  <p>I really appreciate their support to convert our accounts as computerized. Their service is reliable and secured.”</p>
              </blockquote>
              <cite><span>Mr. Mothilal,</span><br>
                Mothilal Jwellery, Kumbakonam.
              </cite>
          </div>
      </div>
  </div>

  <hr style="margin: 60px auto; opacity: .5;">
  </div>
<!----------------------------------------------TESTIMONIAL FIGURE--------------------------------------->

