<div class="p-2 text-black bg-info text-center" style="margin-bottom:0;">
    <div class="col-12">
        <h3 class="text-left">Get In Touch</h3>
    </div>
</div>
<div class="p-3 bg-dark text-white text-center" style="margin-bottom:0">
    <div class="container-fluid col-xs-12 mb-3" style="width: 100%;">
        <div class="row">
            <!--<div class="col-md-4 col-sm-12 col-xs-12  text-left">
				<img src="./../../assets/img/logo.png" style="width: 75%;">
					 <p>
                        Annaa Silicon Technology is a complete IT Services company with major focus on Retail, religion
                        and Health Verticals. The Company is head quartered in Kumbakonam, India and recently expanded
                        its operations in Chennai, India. The Company is mentored by Domain Experts and entrepreneurs
                        from Phoenix, USA and London UK.
                    </p>
			</div> -->
            <div class="col-md-2 col-sm-12 col-xs-12  text-left">
                <h4 class="text-uppercase font-weight-bold">industries</h4>
                <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p><a class="text-light font-weight-bold">Finance & Chit</a></p>
                <p><a class="text-light font-weight-bold">Retail</a></p>
                <p><a class="text-light font-weight-bold">Healthcare</a></p>
                <p><a class="text-light font-weight-bold">Education</a></p>
                <p><a class="text-light font-weight-bold">Religious</a></p>
                <p><a class="text-light font-weight-bold">Value Added Services</a></p>
                <p><a class="text-light font-weight-bold">Hospitality</a></p>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12  text-left">
                <h4 class="text-uppercase font-weight-bold">services</h4>
                <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p><a routerLink="/custom-software-development" class="text-primary font-weight-bold">Custom Software Development</a></p>
                <p><a routerLink="/web-development" class="text-primary font-weight-bold">Web Development, Design and ReEngineering</a></p>
                <p><a routerLink="/mobile-development" class="text-primary font-weight-bold"> Mobile Application Development</a></p>
                <p><a routerLink="/web-hosting" class="text-primary font-weight-bold"> Web Hosting, SEO Services</a></p>
                <p><a routerLink="/maintenance" class="text-primary font-weight-bold"> Application Maintenance & Support</a></p>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12  text-left">
                <h4 class="text-uppercase font-weight-bold">company</h4>
                <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p><a routerLink="/about-us" class="text-primary font-weight-bold">About Us</a></p>
                <p><a routerLink="/custom-software-development" class="text-primary font-weight-bold">Services</a></p>
                <p><a routerLink="/verticals" class="text-primary font-weight-bold">Verticals</a></p>
                <p><a routerLink="/testimonials" class="text-primary font-weight-bold">Testimonials</a></p>
                <p><a routerLink="/upload-cv" class="text-primary font-weight-bold">Careers</a></p>
                <p><a routerLink="/contact-us" class="text-primary font-weight-bold">Contact Us</a></p>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12  text-left">
                <h4 class="text-uppercase font-weight-bold">contact us</h4>
                <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <!-- <img src="./../../assets/img/brand.png"> -->
                <h5 class="text-light font-weight-bold"><i class="fas fa-home mr-3 text-light"></i>Kumaragam 2nd Floor,Abimukeswarar East Street, Kumbakonam</h5>
                <h5 class="text-light font-weight-bold"><i class="fas fa-envelope mr-3 text-light"></i> info@annaasilicontechnology.com</h5>
                <h5 class="text-light font-weight-bold"><i class="fas fa-envelope mr-3 text-light"></i> annaasilicontechnology@gmail.com</h5>
					<!-- <h5 class="text-light font-weight-bold"><i class="fas fa-envelope mr-3 text-light"></i> sales@annasilicon.com</h5> -->
                <h5 class="text-light font-weight-bold"><i class="fas fa-phone mr-3 text-light"></i> +91 9344303015</h5>
                <!-- <h5 class="text-light font-weight-bold"><i class="fas fa-phone mr-3 text-light"></i> +91 9514687687, +91 9543361507</h5> -->
                <h5 class="text-light font-weight-bold"><i class="fa fa-volume-control-phone mr-3 text-white" aria-hidden="true"></i> 0435 2427274</h5>
            </div>
        </div>
    </div>

    <div class="row border border-left-0 border-left-0 border-secondary">
        <div class="col-md-6 col-sm-6 col-xs-12 d-none d-sm-block">
            <h5 class="mt-2 pt-4 text-left"><i class="fa fa-copyright"> 2017 AnnaaSiliconTechnology - All Rights Reserved</i></h5>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 text-right">
            <div id="social">
                <a class="facebookBtn smGlobalBtn" href="https://www.facebook.com/annaasilicontechnologykmu"></a>&nbsp;
                <a class="twitterBtn smGlobalBtn" href="#"></a>&nbsp;
                <a class="googleplusBtn smGlobalBtn" href="#"></a>&nbsp;
                <a class="linkedinBtn smGlobalBtn" href="#"></a>&nbsp;
                <a class="skypeBtn smGlobalBtn" href="#"></a>&nbsp;
                <!-- <a class="pinterestBtn smGlobalBtn" href="#" ></a>&nbsp;
				<a class="tumblrBtn smGlobalBtn" href="#" ></a>&nbsp;
				<a class="rssBtn smGlobalBtn" href="#" ></a>&nbsp; -->
            </div>
        </div>
    </div>

    <div class=" col-xs-12 text-center d-block d-sm-none" style="border-top: 1px solid #f5f5f5;">
        <p class="mt-2">Designed by</p>
        <a href="" class="font-weight-bolder text-center"> AnnaaSiliconTechnology.Pvt.Ltd.,</a>
    </div>
</div>
