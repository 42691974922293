<div class="text-center">
    <div class="contact-detail">
        <img src="./../../../assets/img/cv 1.gif" alt="Notebook" style="width:100%; height: 300px;">
        <div class="content p-4">
            <br /><br /><br />
            <h1 class="font-weight-bolder align-middle text-uppercase">Upload Your CV</h1>
        </div>
    </div>
</div>

<div class="container mt-2 mb-1">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card">
                <!-- <div class="p-2 border-top-0 border-left-0 border-right-0 border-light border text-light text-center font-weight-bolder">
                    <h3>Upload Your CV</h3>
                </div> -->
                <div class="card-body text-dark text-justify">
                    <form>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputEmail4">Full Name *</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <i class="fa fa-user icon" aria-hidden="true"></i>
                                  </span>
                                </div>
                                <input type="Name" class="form-control" id="inputEmail4" placeholder="Full Name">
                              </div>
                          </div>
                          <div class="form-group col-md-6">
                                <label for="inputPassword4">Apply for the Post of *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-id-card icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="text" class="form-control" id="inputPassword4" placeholder="Position">
                                </div>
                          </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="inputEmail4">Email *</label>
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                      <i class="fa fa-envelope  icon" aria-hidden="true"></i>
                                  </span>
                                  </div>
                                  <input type="email" class="form-control" id="inputEmail4" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Mobile Number *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-mobile icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="number" class="form-control" id="inputPassword4" placeholder="Mobile No">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">Current Location *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-map-marker icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="text" class="form-control" id="inputEmail4" placeholder="Location">
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Home Town *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-home icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="text" class="form-control" id="inputPassword4" placeholder="Home Town">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="inputEmail4">Role *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-group icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <select id="inputState" class="form-control">
                                    <option selected>Choose...</option>
                                        <option>...</option>
                                    </select>
                                    </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="inputPassword4">Total Experience *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-clock icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <select id="inputState" class="form-control">
                                        <option selected>Choose...</option>
                                        <option>...</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="inputPassword4">Select CV *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                          <i class="fa fa-upload icon" aria-hidden="true"></i>
                                      </span>
                                    </div>
                                    <div class="custom-file">
                                      <input type="file" class="custom-file-input" id="inputGroupFile01">
                                      <label class="custom-file-label bg-light" for="inputGroupFile01">Choose file</label>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="inputEmail4">Key Skills *</label>
                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <i class="fa fa-key icon" aria-hidden="true"></i>
                                  </span>
                                </div>
                                <input type="text" aria-rowspan="2" class="form-control" id="inputEmail4" placeholder="Key Skills">
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Write About You *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-pencil-square icon" aria-hidden="true"></i>
                                    </span>
                                    </div>
                                    <input type="text" aria-rowspan="2" class="form-control" id="inputPassword4" placeholder="Write About You">
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-md-center">
                            <div class="col-md-6 col-sm-12 col-12 text-right">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn bg-light btn-outline-light">
                                        <i class="fa fa-refresh icon"></i>
                                    </button>
                                    <button type="submit" class="btn btn-light text-light"><span class="icon">Reset</span></button>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-12 text-left">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="submit" class="btn btn-light text-light"><span class="icon">Send</span></button>
                                    <button type="button" class="btn bg-light btn-outline-light">
                                        <i class="fa fa-paper-plane icon"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
</div>
