import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  moveEllora()
  {
    this.router.navigate(['/child-abstract',1])
  }

  moveSchool()
  {
    this.router.navigate(['/child-abstract',2])
  }

  moveLodge()
  {
    this.router.navigate(['/child-abstract',3])
  }

  moveInventory()
  {
    this.router.navigate(['/child-abstract',4])
  }

  moveAntibiotic()
  {
    this.router.navigate(['/child-abstract',5])
  }

  moveTicket()
  {
    this.router.navigate(['/child-abstract',6])
  }

  moveEngineering()
  {
    this.router.navigate(['/child-abstract',7])
  }

  moveStipend()
  {
    this.router.navigate(['/child-abstract',8])
  }
}
