<div class="header-gradient p-2">
	<div class="card product-card bg-success">
		<div class="card-body">
			<h1 class="text-center">
				ANNAA SILICON TECHNOLOGY PRIVATE LIMITED <sup><i class="fas fa-registered"></i></sup>
			</h1>
			<!-- <h5 class="text-light font-italic company">
				Technology Makes Pleasure
			</h5> -->
		</div>
		<br />
		<div class="row pb-3 w3-animate-left">
			<div class="offset-md-1 col-md-2 col-sm-12 col-xs-12">
				<img src="../../assets/img/Colla.jpg" class="rounded" style="width: 100%; height: 200px;" alt="ICON" />
				<h5 class="text-center text-dark"><b>Work Collaboration</b></h5>
			</div>
			<div class="col-md-2 col-sm-12 col-xs-12">
				<img src="./../../assets/img/Bulk_1-removebg-preview.png" class="rounded" style="width: 100%; height: 200px;" alt="ICON" />
				<h5 class="text-center text-dark"><b>Bulk SMS Gateway</b></h5>
			</div>
			<div class="col-md-2 col-sm-12 col-xs-12">
				<img src="./../../assets/img/BARCODE 1.png" class="rounded" style="width: 100%; height: 200px;" alt="ICON" />
				<h5 class="text-center text-dark"><b>Barcode Solutions</b></h5>
			</div>
			<div class="col-md-2 col-sm-12 col-xs-12">
				<img src="./../../assets/img/Mobile 1.jpg" class="rounded" style="width: 100%; height: 200px;" alt="ICON" />
        <h5 class="text-center text-dark"><b>Mobile Application</b></h5>
      </div>
			<div class="col-md-2 col-sm-12 col-xs-12">
				<img src="./../../assets/img/payroll.png" class="rounded" style="width: 100%; height: 200px;" alt="ICON" />
				<h5 class="text-center text-dark"><b>Payroll Management</b></h5>
			</div>
		</div>
	</div>
</div>

<!--	-------  Our Services	-------	 -->

<div class="p-4">
	<div class="container-fluid">
		<div class="container row">
			<div class="border border-bottom-0 border-left-0 border-top-0 p-2">
				<span class="fa fa-cogs head-icon"></span>
			</div>
			<h2 class="text-left text-dark mb-4 font-weight-bolder text-uppercase p-2">
				services we provider
			</h2>
		</div>
		<br />
		<div class="row w3-animate-bottom">
			<div class="col-md-3 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-2">
					<div class="text-center">
						<span class="fa fa-globe service-icon" aria-hidden="true"></span>
            <div class="animate__animated animate__slideInLeft">
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Web Development
						</h6>
						<p class="text-left text-muted">
							We Craft the website keeping the credibility of your company to add more value to your business.
						</p><br />
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">
							Read More
						</button>
					</div>
				</div>
        </div>
			</div>
			<div class="col-md-3 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-android service-icon" aria-hidden="true"></span>
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							App Development
						</h6>
						<p class="text-left text-muted">
							We Craft the website keeping the credibility of your company to add more value to your business.
						</p><br>
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">
							Read More
						</button>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-wrench service-icon" aria-hidden="true"></span>
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							App Maintenance
						</h6>
						<p class="text-left text-muted">
							We Craft the website keeping the credibility of your company to add more value to your business.
						</p><br>
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">
							Read More
						</button>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-server service-icon" aria-hidden="true"></span>
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Web Hosting
						</h6>
						<p class="text-left text-muted">
							We Craft the website keeping the credibility of your company to add more value to your business.
						</p><br>
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">
							Read More
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<br/>
</div>

<!--	------	 About US	----------	 -->
	<div class="row col  text-center">
		<div class="col-md-6 col-sm-12 col-xs-12" style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
			<img src="../../assets/img/Little.gif" style="width: 100%;" />
		</div>

		<div class="bg-dark col-md-6 col-sm-12 col-xs-12">
			<div class="row text-right">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2">
					<span class="fa fa-user head-icon"></span>
				</div>
				<h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
					Little About Us:
				</h2>
			</div>
			<p class="text-light text-justify">
				<br />
				<span class="first-letter">A</span>nnaa Silicon Technology is a complete IT Services company with major focus on Retail, religion and Health Verticals. The Company is head quartered in Kumbakonam, India
				and recently expanded its operations in Chennai, India. The Company is mentored by Domain Experts and entrepreneurs from Phoenix, USA and London UK.The Company has been
				delivering end-to-end software development, quality assurance and Annual maintenance support solutions to clients ranging from Fortune 1000 companies to startups in all
				stages of funding since 2003.Our mission is to provide high value IT solutions and services to our customers with distinctive competence and integrity by working together
				for the shared long term objectives.
			</p>
			<div class="col-xs-12 text-right pr-3">
				<button type="button" class="btn btn-outline-primary waves-effect p-2">Read More <span class="fa fa-arrow-right"></span></button>
			</div>
		</div>
	</div>

<!--	-------	 Our Products	----------	 -->
<div class="p-4" style="background-image: url(./../../assets/img/parallax/parallax2.jpg); opacity: 99%;">
	<div class="row text-right">
		<!-- <div class="border border-bottom-0 border-left-0 border-top-0 p-2">
			<span class="fa fa-user head-icon"></span>
		</div> -->
		<h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
			Our clients
		</h2>
	</div>
	<div class="container-fluid">
		<div id="slider-container" class="slider">
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1574482620811-1aa16ffe3c82?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1574451311232-cb647e9d71f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1574449423472-4bc6a3d2473d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1574459472673-09bbda49102a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=928&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1479981280584-037818c1297d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1511593358241-7eea1f3c84e5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1560259324-12a044e67c34?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20" alt="">
			</div>
			<div class="slide">
					<img src="https://images.unsplash.com/photo-1532787799187-93655e51d472?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20" alt="">
			</div>
			<div (click)="prev()" class="control-prev-btn mt-4">
				 <i class="fas fa-arrow-left"></i>
			</div>
			<div (click)="next()" class="control-next-btn mt-4">
				<i class="fas fa-arrow-right"></i>
			</div>
		</div>
		<div class="overlay"></div>
	</div>
</div>

<!-- 	---------	AUTO MODEL	----------	  -->
<div class="modal fade" id="myModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
			<button type="button" class="close" data-dismiss="modal">&times;</button>
			<div class="p-5">
				<div class="row">
					<div class="col-md-4 col-sm-12 col-xs-12">
						<div class="profile-sidebar">
							<img src="./../../assets/img/home/md.png"  class="rounded-circle" alt="" />
					</div>
					</div>
					<div class="col-md-8 col-sm-12 col-xs-12 text-center">
						<div class="pt-5">
							<h2 class="font-weight-bolder">K.Gnanaguru</h2>
							<h3 class="font-weight-normal">Managing Director</h3>
							<h4 class="profile-position">DIN :  07967398</h4>
							<h5 style="color: black;">
								<i class="fas fa-phone"> 9344303015, 95146 87687</i>
							</h5>
							<h5 style="color: black;">
								<i class="fas fa-phone"> 9843683020, 95433 61507</i>
							</h5>
					</div>
				</div>
			</div>
		</div>
    </div>

        <!-- Modal footer -->
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div> -->

      </div>
    </div>
  </div>
