import { Component, OnInit } from '@angular/core';


declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $(document).ready(function() {
   //   $("#myModal").modal('show');
    });
    
    setTimeout(function() {
      $('#myModal').modal('hide');
    }, 2000);

    $(".slide img").on("click" , function(){
      $(this).toggleClass('zoomed');
      $(".overlay").toggleClass('active');
      })

  }

  prev(){
    document.getElementById('slider-container').scrollLeft -= 270;
  }
  
  next()
  {
    document.getElementById('slider-container').scrollLeft += 270;
  }
  
}
