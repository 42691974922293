import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { HomeNavbarComponent } from './home-navbar/home-navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { VerticalsComponent } from './verticals/verticals.component';
import { ClientsComponent } from './clients/clients.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CustomSoftwareDevelopmentComponent } from './services/custom-software-development/custom-software-development.component';
import { WebDevelopmentComponent } from './services/web-development/web-development.component';
import { MobileDevelopmentComponent } from './services/mobile-development/mobile-development.component';
import { WebHostingComponent } from './services/web-hosting/web-hosting.component';
import { MaintenanceComponent } from './services/maintenance/maintenance.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UploadCvComponent } from './careers/upload-cv/upload-cv.component';
import { AbstractComponent } from './clients/abstract/abstract.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeNavbarComponent,
    FooterComponent,
    HomePageComponent,
    AboutUsComponent,
    VerticalsComponent,
    ClientsComponent,
    TestimonialsComponent,
    CustomSoftwareDevelopmentComponent,
    WebDevelopmentComponent,
    MobileDevelopmentComponent,
    WebHostingComponent,
    MaintenanceComponent,
    ContactUsComponent,
    UploadCvComponent,
    AbstractComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
