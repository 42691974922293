<!----------------------------------------TITLE-------------------------------------------------------->
<div class="bg-light">
  <div class="text-center">
      <div class="contact-detail">
          <img src="./../../../assets/img/Gif 5.gif" alt="Notebook" style="width:100%; height: 300px;">
          <div class="content p-4">
              <br /><br /><br />
              <h1 class="font-weight-bolder align-middle text-uppercase">WEB DEVELOPMENT</h1>
          </div>
      </div>
  </div>
<!---------------------------------------------TITLE END------------------------------------------------------->
<div class="pb-4"></div>
<div class="container">
  <div class="row">
    <div class="col">
      <h6 class="heading justify-content-center" >WEB DEVELOPMENT</h6><br><br>
    </div>
    </div>
    </div>
<!--------------------------------------------WEB DEV PARA------------------------------------------------>
<div class="container">
  <div class="row">
    <div class="col-md-5 col-sm-12 col-xs-12" >
      <div class="card c">
        <div class="top-sec">
          <img src="../../../assets/img/Gif 3.gif" style="width:100%;">
        </div>
        <div class="bottom-sec">

        </div>
      </div>
      <!-- <img src="./../../../assets/img/home/serv-wd/web3.png" style="width: 100%;height: 100%;"> -->
    </div>
    <div class="col-md-7 col-sm-12 col-xs-12" >
        <p class="paragraph justify-content-center" style="text-indent: 5%;">
              We Craft the website keeping the credibility of your company to add more value to your
              business. Our web design enables world class browsing experience on Desktop, Laptop,
              IPAD and hand held Mobile devices. Responsive web design is focused around providing an
              intuitive and gratifying experience for everyone.
              <!-- Web Applications can have three components: an intranet, an extranet, and a website.
              With a Web Application, data can be easily shared anywhere there is an Internet connection. -->
        </p>
        <p class="paragraph justify-content-center" style="text-indent: 5%;">We always use the latest tools in technology and design to meet and exceed our client's goals.
                 Web Designing is an art and we assure you that our creative team craft your web pages
                 instead of just designing.
        </p>
        <p class="paragraph justify-content-center" style="text-indent: 5%;">
               We always use the latest tools in technology and design to meet and exceed our client's goals.
               Web Designing is an art and we assure you that our creative team craft your web pages instead of
               just designing.
        </p>
        <p class="paragraph justify-content-center" style="text-indent: 5%;">
              We leverage Agile methodology and work with requirements of our client and then set a
              customized strategy best suited for the clients.
        </p>


     </div>
  </div>
</div>
<button onclick="topFunction()" id="myBtn" title="Go to top"></button>
<!--------------------------------------------WEB DEV PARA END------------------------------------------------->
<div class="pb-5"></div>

<!-------------------------------------------------WEB APPLICATION--------------------------------------------->
<div class="p-4">
	<div class="container">
		<div class="container row">
		</div>
		<br />
		<div class="row w3-animate-bottom">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card1 service-card border-0 p-4">
					<div class="text-center">
						<!-- <span class="fa fa-eye service-icon" aria-hidden="true"></span> -->

						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
              INTRANET
						</h6>
						<p class="text-left text-muted"style="text-indent: 5%;">An intranet allows employees to access a site where data can be securely collected and reported on.
              The intranet can be configured to be viewed from only a LAN or through the Internet.
              This portion of a Web Application provides tremendous business efficiency.
						</p><br />
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">

						</button>
					</div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card1 service-card border-0 p-4">
					<div class="text-center">
						<!-- <span class="fa fa-rocket service-icon" aria-hidden="true"></span> -->
						<h6 class="text text-center text-dark text-uppercase font-weight-bolder pb-1">
							EXTRANET
						</h6>
						<p class="text-left text-muted" style="text-indent: 5%;">An extranet displays restricted data for authorized customers or partners.
              Intranet users have complete control over the content and user management for your extranet.
						</p><br>
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">

						</button>
					</div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card1 service-card border-0 p-4">
					<div class="text-center">
						<!-- <span class="fa fa-wrench service-icon" aria-hidden="true"></span> -->
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							WEBSITE
						</h6>
						<p class="text-left text-muted" style="text-indent: 5%;">A website is completely public-facing.
              It describes your business and entices potential customers or partners to join you.
						</p><br>
						<button class="btn service-btn text-white mt-3 pt-2 pb-2">

						</button>
					</div>
				</div>
			</div>

		</div>
	</div>
	<br/>
</div>

<!-----------------------------------------WEB APPLICATION END------------------------------------------------->

<!---------------------------------------------CARD FOR BENEFIT WITH CAROUSEL--------------------------------->
<div class="pb-5"></div>
<div class="container py-3">
  <div class="title h4 text-center">Benefits of Web Design ReEngineering</div>
  <!--------------------------------------------CARD START ----------------------------------------------->
  <div class="card">
    <div class="row ">

      <div class="col-md-7 px-3">
        <div class="card-block px-6">
          <h4 class="card-title"><u>Benefits of Web Design ReEngineering</u> </h4>
          <p class="card-text" style="text-indent: 5%;">Drive Traffic to the Website</p>
          <p class="card-text" style="text-indent: 5%;">Increase Business value and in turn drive growth</p>
          <p class="card-text" style="text-indent: 5%;">Enhanced Customer Experience</p>
          <p class="card-text" style="text-indent: 5%;">Integrate with Payment gateway enabling Cash Less transactions</p>
          <p class="card-text" style="text-indent: 5%;">Leverage Best in Class communication methods with the Clients including SMS, Voice
          </p>

        </div>
      </div>
      <!--------------------------------------CAROUSEL START------------------------------------------------>
      <div class="col-md-5">
        <div id="CarouselTest" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#CarouselTest" data-slide-to="0" class="active"></li>
            <li data-target="#CarouselTest" data-slide-to="1"></li>
            <li data-target="#CarouselTest" data-slide-to="2"></li>

          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block" src="../../../assets/img/WEB 1.jpg" alt="img" style="width:100%;height:100%;">
            </div>
            <div class="carousel-item">
              <img class="d-block" src="./../../../assets/img/WEB 3.jpg" alt="img"  style="width:100%;height:100%;">
            </div>
            <div class="carousel-item">
              <img class="d-block" src="./../../../assets/img/web 2.jpg" alt="img"  style="width:100%;height:100%;">
            </div>
            <div class="carousel-item">
              <img class="d-block" src="./../../../assets/img/web 5.jpeg" alt="img"  style="width:100%;height:100%;">
            </div>
            <div class="carousel-item">
              <img class="d-block" src="./../../../assets/img/web 4.jpeg" alt="img"  style="width:100%;height:100%;">
            </div>
            <a class="carousel-control-prev" href="#CarouselTest" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
            <a class="carousel-control-next" href="#CarouselTest" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
          </div>
        </div>
      </div>
      <!----------------------------------------END OF CAROUSEL---------------------------------------------->
    </div>
  </div>
  <!--------------------------------------------END OF CARD-------------------------------------------------->

</div>
<!-------------------------------------------CARD FOR BENFITS END------------------------------------------->
