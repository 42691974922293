<!-----------g--------------------------------------------TITLE----------------------------------------------------->
<div class="bg-light">
  <div class="text-center">
      <div class="contact-detail">
          <img src="./../../../assets/img/custom-software-development 1.jpg" alt="Notebook" style="width:100%; height:300px;">
          <div class="content p-4">
              <br /><br /><br />
              <h1 class="font-weight-bolder align-middle text-uppercase">CUSTOM SOFTWARE DEVELOPMENT</h1>
          </div>
      </div>
  </div>
</div>
<!----------------------------------------------TITLE END--------------------------------------------------->
<!------------------------------------CUSTOM SOFTWARE DEVELOPMENT------------------------------------->
<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12 " style="margin-top: 50px;">
      <h3><b><u>custom software development if any of the following describe your
             business</u></b></h3>
          <ol >
             <li>
              Your business uses an off-the-shelf software package, but it doesn't do everything you need.
            </li>
            <li>
              You have to modify your business practices to fit the software you're using.
            </li>
            <li>
              The software you use has features that you don't need that slow you down and make training difficult.
            </li>
            <li>
              Your business uses Manual/Labor intensive processes /Excel spreadsheets to track some or all of its data.
            </li>
            <li>
              Your business had to purchase several applications to accomplish tasks that would ideally all be
              done in one application.
            </li>
            <li>
              You or someone on your staff has developed an Access database to manage your data,
              but it doesn't meet all your needs or is unreliable.
            </li>
            <li>
              You have to hire a third party company to perform business processes you would
              do in-house if you had the right tools.
            </li>
            <li>
              You have to hire additional staff to perform business processes that could be automated.
            </li>
            <li>
              The software you're using doesn't allow you to produce all the reports you need.
            </li>
            </ol>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12" style="margin-top: 50px;" >

          <img src="./../../../assets/img/Hand.jpg" style="width:100%;height: 100%;">

    </div>


    </div>

</div>
<button onclick="topFunction()" id="myBtn" title="Go to top"></button>
  <!--------------------------------------------CSD END----------------------------------------------------->
 <!------------------------------------CARD FOR ROLE------------------------------------------------------>
<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12"  >
      <h1 class="heading"></h1>
        <div class="card-container">
            <div class="card" style="--image: url('./../../../assets/img/home/serv-cs/css1.jpg');
                --angle: -5deg; --x: 5%; --y: 15%; --caption: 'EVERY BUSINESS SIZE' ">
            </div>

            <div class="card" style="--image: url('./../../../assets/img/home/serv-cs/cs2.jpg');
                --angle: -1deg; --x: -10%; --y: -20%; --caption: 'EVERY INDUSTRY' ">
            </div>

            <div class="card" style="--image: url('./../../../assets/img/home/serv-cs/cs3.jpg');
                --angle: -4deg; --x: -20%; --y: 5%; --caption: 'EVERY ROLE' ">
            </div>
            <div class="card" style="--image: url('./../../../assets/img/home/serv-cs/cs1.png');
            --angle: 7deg; --x: 10%; --y: -7%; --caption: 'EVERY USER' "></div>

        </div>
      </div>

      <!---------------------------------------------CARD ROLE END------------------------------------------->
      <!------------------------------------BENEFIT OF CSD------------------------------------------------------->
      <div class="col-md-6 col-sm-12 col-xs-12" style="padding-left: 40px;" >
        <h3><b><u>Benefits of Custom Software Development</u></b></h3>
       <ul class="live" >
          <li class="li">
            Maximize employee efficiency
          </li>
          <li class="li">
            Streamline business processes
          </li>
          <li class="li">
            Reduce overhead
          </li>
          <li class="li">
            Improve service to your customers
          </li>
          <li class="li">
            Obtain business critical data
          </li>
          <li class="li">
            Improve data security
          </li>
          <li class="li">
            Gain a competitive edge over your competition
          </li>
          <li class="li">
            Fast turn-around times on the changes you need
          </li>
          <li class="li">
            No ongoing license costs
          </li>
        </ul>
    </div>
  </div>
</div>
<!-------------------------------------BENEFIT OF CSD END----------------------------------------->



