<!------------------------------------------------------- TITLE --------------------------------------------->
<div class="bg-light">
  <div class="text-center">
      <div class="contact-detail">
          <img src="./../../../assets/img/Mobile.gif" alt="Notebook" style="width:100%; height:300px;">
          <div class="content p-4">
              <br /><br /><br />
              <h1 class="font-weight-bolder align-middle text-uppercase">MOBILE DEVELOPMENT</h1>
          </div>
      </div>
  </div>
<!------------------------------------------------------- TITLE END--------------------------------------------->
  <!------------------------------------------------HEADING --------------------------------------------->
<div class="container">
  <div class="row">
    <div class="col">
      <div class="heading justify-content-center" >MOBILE DEVELOPMENT</div>
    </div>
    </div>
    </div>
<!--------------------------------------------HEADING END --------------------------------------------->

<!-------------------------------------------------MOB DEV --------------------------------------------->
<div class="container">
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
                    <p>Smartphones and tablets are replacing desktops and laptops,
                        and users are demanding more and more functionality from their portable devices.
                         Any software today needs a solid mobile strategy – or it's dead in the water.
                         Annaa Silicon has been a pioneer in mobile software development, creating native mobile apps
                          as well as injecting mobile functionality into a range of enterprise software solutions.

                        Annaa Silicon is currently developing mobile apps and mobile software
                        components on iOS (iPhone and iPad), Android, Blackberry and Windows Phone 7.</p>

          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="card c">
              <div class="top-sec">
                <img src="./../../../assets/img/home/serv-md/mobile.jpg" style="width:100%;">
              </div>
              <div class="bottom-sec">

              </div>
            </div>
          </div>
    </div>
</div>
<!-------------------------------------------------MOB DEV END-------------------------------------------->
<button onclick="topFunction()" id="myBtn" title="Go to top"></button>
<!---------------------------------------------CARD FOR BENEFIT WITH CAROUSEL--------------------------------->
<!-- <div class="parallax"></div> -->
<div class="container py-3">
  <div class="title h1 text-center"></div>
  <!--------------------------------------------CARD START ----------------------------------------------->
  <div class="card">
    <div class="row ">
<!--------------------------------------CAROUSEL START------------------------------------------------>
<div class="col-md-5">
  <div id="CarouselTest" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#CarouselTest" data-slide-to="0" class="active"></li>
      <li data-target="#CarouselTest" data-slide-to="1"></li>


    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block" src="./../../../assets/img/home/serv-md/MS3.jpg" alt="img" style="width:100%;height:100%;">
      </div>
      <div class="carousel-item">
        <img class="d-block" src="./../../../assets/img/home/serv-md/MS2.jpg" alt="img"  style="width:100%;height:100%;">
      </div>

      <a class="carousel-control-prev" href="#CarouselTest" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="sr-only">Previous</span>
</a>
      <a class="carousel-control-next" href="#CarouselTest" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="sr-only">Next</span>
</a>
    </div>
  </div>
</div>
<!----------------------------------------END OF CAROUSEL---------------------------------------------->
      <div class="col-md-7 px-3">
        <div class="card-block px-6">
          <p class="card-text" >Push notifications<br>
          e-Commerce Application<br>
          Mobile analytics applications<br>
          Expand your data systems to the mobile arena<br>
         Create apps to make your workforce more efficient
          </p>

        </div>
      </div>

    </div>
  </div>
  <!--------------------------------------------END OF CARD-------------------------------------------------->

</div>
<!-------------------------------------------CARD FOR BENFITS END------------------------------------------->

